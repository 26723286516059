















































































































































































import { Component, Vue } from 'vue-property-decorator';
import NewPasswordViewModel from '@/vue-app/view-models/components/on-boarding/reset-password/new-password-view-model';

@Component({ name: 'NewPassword' })
export default class NewPassword extends Vue {
  new_password_view_model = Vue.observable(new NewPasswordViewModel(this));

  created() {
    if (this.$route?.query?.token) {
      const token = this.$route?.query?.token.toString();
      this.new_password_view_model.initialize(token);
    }
  }
}
