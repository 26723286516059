var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"py-8 px-lg-6",attrs:{"justify":"center","align":"start"}},[_c('v-col',{attrs:{"cols":"10"}},[_c('h1',{staticClass:"text-center primary--text font-weight-medium"},[_vm._v(" Restablecer Contraseña ")]),_c('p',{staticClass:"text-body-1 font-weight-bold primary--text mt-10"},[_vm._v(" Introduzca una nueva contraseña: "),_c('v-tooltip',{attrs:{"bottom":"","color":"accent","max-width":_vm.$vuetify.breakpoint.mdAndUp ? '30%' : '50%'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":"accent"}},on),[_vm._v(" mdi-alert-circle-outline ")])]}}])},[_c('span',[_vm._v(" Una contraseña segura es una que otras personas no puedan determinar fácilmente adivinándola o utilizando programas automáticos. ")])])],1)]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","lg":"10","xl":"8"}},[_c('v-form',{ref:"form",staticClass:"form-register px-5",on:{"submit":function($event){$event.preventDefault();return _vm.new_password_view_model.submitResetPassword.apply(null, arguments)}},model:{value:(_vm.new_password_view_model.is_valid_form),callback:function ($$v) {_vm.$set(_vm.new_password_view_model, "is_valid_form", $$v)},expression:"new_password_view_model.is_valid_form"}},[_c('v-text-field',{attrs:{"name":"password","append-icon":_vm.new_password_view_model.inputs_config.password.type === 'password'
              ? 'mdi-eye-off'
              : 'mdi-eye',"type":_vm.new_password_view_model.inputs_config.password.type,"error-messages":_vm.new_password_view_model.inputs_config.password.errors,"label":"Contraseña","placeholder":"********","hint":"Al menos 8 caracteres","counter":"","autocapitalize":"none"},on:{"click:append":function($event){_vm.new_password_view_model.inputs_config.password.type === 'password'
              ? (_vm.new_password_view_model.inputs_config.password.type = 'text')
              : (_vm.new_password_view_model.inputs_config.password.type = 'password')},"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.new_password_view_model.inputs.password),callback:function ($$v) {_vm.$set(_vm.new_password_view_model.inputs, "password", $$v)},expression:"new_password_view_model.inputs.password"}}),_c('v-text-field',{attrs:{"name":"confirm_password","append-icon":_vm.new_password_view_model.inputs_config.confirm_password.type === 'password'
              ? 'mdi-eye-off'
              : 'mdi-eye',"type":_vm.new_password_view_model.inputs_config.confirm_password.type,"placeholder":"********","label":"Confirmar contraseña","rules":_vm.new_password_view_model.inputs_config.confirm_password.rules,"autocapitalize":"none"},on:{"click:append":function($event){_vm.new_password_view_model.inputs_config.confirm_password.type === 'password'
              ? (_vm.new_password_view_model.inputs_config.confirm_password.type = 'text')
              : (_vm.new_password_view_model.inputs_config.confirm_password.type = 'password')},"contextmenu":function($event){$event.preventDefault();},"copy":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();}},model:{value:(_vm.new_password_view_model.inputs.confirm_password),callback:function ($$v) {_vm.$set(_vm.new_password_view_model.inputs, "confirm_password", $$v)},expression:"new_password_view_model.inputs.confirm_password"}}),_c('p',{staticClass:"secondary--text mt-5"},[_vm._v(" Por tu seguridad la contraseña debe incluir: ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-row',{staticClass:"secondary--text"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"mb-2 mt-2",attrs:{"align":"center"}},[(_vm.new_password_view_model.is_password_min_length_valid)?_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"primary"}},[_vm._v(" mdi-circle ")]):_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"#D8D8D8"}},[_vm._v(" mdi-circle ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Mínimo 8 caracteres ")])],1),_c('v-row',{staticClass:"mb-2",attrs:{"align":"center"}},[(
                      _vm.new_password_view_model
                        .password_contains_upper_case_one_lower_case_and_one_digit
                    )?_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"primary"}},[_vm._v(" mdi-circle ")]):_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"#D8D8D8"}},[_vm._v(" mdi-circle ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" Al menos 1 mayúscula, 1 minúscula y 1 número ")])],1),_c('v-row',{staticClass:"mb-2",attrs:{"align":"center"}},[(_vm.new_password_view_model.password_has_no_consecutive_characters)?_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"primary"}},[_vm._v(" mdi-circle ")]):_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"#D8D8D8"}},[_vm._v(" mdi-circle ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" No puede contener una secuencia (ej: 123, abc) ")])],1),_c('v-row',{staticClass:"mb-2",attrs:{"align":"center"}},[(_vm.new_password_view_model.password_has_no_consecutive_repeated_characters)?_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"primary"}},[_vm._v(" mdi-circle ")]):_c('v-icon',{staticClass:"d-block me-6",attrs:{"x-small":"","color":"#D8D8D8"}},[_vm._v(" mdi-circle ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" No puede contener 3 caracteres iguales seguidos ")])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"my-16",attrs:{"justify":"space-evenly"}},[_c('v-col',[_c('v-btn',{staticClass:"px-4 px-lg-10 py-5 font-weight-bold float-right",attrs:{"color":"accent","type":"submit","loading":_vm.new_password_view_model.is_loading}},[_vm._v(" Continuar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }