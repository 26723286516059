const LIMIT_OF_CONSECUTIVE_EQUAL_CHARACTERS = 3;

export const noConsecutiveRepeatedCharactersValidator = (password: string) => {
  let is_valid_password = false;
  if (password.length > LIMIT_OF_CONSECUTIVE_EQUAL_CHARACTERS) {
    const normalized_password: string = password.toLowerCase();
    const password_check_iterations = normalized_password.length
      - (LIMIT_OF_CONSECUTIVE_EQUAL_CHARACTERS - 1);

    for (let index = 0; index < password_check_iterations; index++) {
      const substring = normalized_password
        .substring(index, LIMIT_OF_CONSECUTIVE_EQUAL_CHARACTERS + index);
      const repeated_characters = substring[0].repeat(LIMIT_OF_CONSECUTIVE_EQUAL_CHARACTERS);

      is_valid_password = !(substring === repeated_characters);

      if (!is_valid_password) return false;
    }
  }

  return is_valid_password;
};
