import Vue from 'vue';
import { requiredRule, RuleResponseType } from '@/vue-app/utils/form-rules';
import TYPES from '@/types';

// Application
import ValidatePasswordRecoveryCommand from '@/modules/authentication/application/commands/validate-password-recovery-command';
import RecoverPasswordCommand from '@/modules/authentication/application/commands/recover-password-command';
import PasswordChangedStatusSubscription from '@/modules/authentication/application/subscriptions/password-changed-status-subscription';

// Domain
import {
  blacklistValidator,
  containsDigitsLowerCaseAndUpperCaseValidator,
  minLengthValidator,
  noConsecutiveCharactersValidator,
  noConsecutiveRepeatedCharactersValidator,
} from '@/modules/register/user/domain/services/password-validators';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Inject from '@/modules/shared/domain/di/inject';

export default class NewPasswordViewModel {
  @Inject(TYPES.VALIDATE_PASSWORD_RECOVERY_COMMAND)
  private readonly validate_password_token_command!: ValidatePasswordRecoveryCommand;

  @Inject(TYPES.RECOVER_PASSWORD_COMMAND)
  private readonly recover_password_command!: RecoverPasswordCommand;

  @Inject(TYPES.PASSWORD_CHANGED_STATUS_SUBSCRIPTION)
  private readonly password_changed_status_subscription!: PasswordChangedStatusSubscription;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  inputs = {
    email: '',
    password: '',
    confirm_password: '',
  };

  inputs_config = {
    password: {
      type: 'password',
      rules: [
        requiredRule,
      ],
      errors: [] as Array<string>,
    },
    confirm_password: {
      type: 'password',
      rules: [
        (value: string): RuleResponseType => value === this.inputs.password || 'Las contraseñas no coinciden.',
      ],
    },
  };

  is_valid_form = false;

  is_loading = false;

  password_token = '';

  readonly view!: Vue;

  constructor(view: Vue) {
    this.view = view;
  }

  get is_password_min_length_valid() {
    return minLengthValidator(this.inputs.password);
  }

  get password_contains_upper_case_one_lower_case_and_one_digit() {
    return containsDigitsLowerCaseAndUpperCaseValidator(this.inputs.password);
  }

  get password_contains_columbus_word_or_email() {
    const email_id = this.inputs.email.split('@')[0];
    return blacklistValidator(this.inputs.password, [email_id]);
  }

  get password_has_no_consecutive_characters() {
    return noConsecutiveCharactersValidator(this.inputs.password);
  }

  get password_has_no_consecutive_repeated_characters() {
    return noConsecutiveRepeatedCharactersValidator(this.inputs.password);
  }

  initialize = async (token: string) => {
    this.setPasswordToken(token);
    await this.verifyPasswordToken();
  }

  setPasswordToken = (token: string) => {
    this.password_token = token;
  }

  verifyPasswordToken = async () => {
    try {
      await this.validate_password_token_command.execute({ token: this.password_token });
    } catch (e) {
      this.message_notifier.showErrorNotification('Token no válido');
      // TODO: replace by Router module
      window.location.href = '/404';
    }
  }

  submitResetPassword = async () => {
    try {
      this.is_loading = true;
      await this.recover_password_command.execute({
        password: this.inputs.password,
        password_confirmation: this.inputs.confirm_password,
        token: this.password_token,
      });

      this.password_changed_status_subscription.execute();

      this.view.$emit('nextStep');
    } catch (e) {
      this.message_notifier.showErrorNotification('Ocurrió un error');
    } finally {
      this.is_loading = false;
    }
  }
}
