const INITIAL_BLACKLISTED_WORDS = ['columbus'];

export const blacklistValidator = (password: string, blacklist_words: Array<string>) => {
  let is_valid_password = false;
  const blacklisted = [
    ...INITIAL_BLACKLISTED_WORDS,
    ...blacklist_words.map((word) => word.toLowerCase()),
  ];
  const normalized_password = password.toLowerCase();

  for (let i = 0; i < blacklisted.length; i++) {
    if (normalized_password.includes(blacklisted[i])) return false;

    is_valid_password = true;
  }

  return is_valid_password;
};
